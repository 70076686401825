









import Vue from 'vue';
import Tablet from '@/components/Tablet.vue';
import GameMap from '@/components/GameMap.vue'
import GameVideo from '@/components/GameVideo.vue'
import Curtain from '@/components/Curtain.vue'

export default Vue.extend({
  name: 'Entrance',
  components: {
    Tablet,
    GameMap,
    GameVideo,
    Curtain
  },
  props: {
  },
  data () {
    return {
      curtainActive: false,
      curtainHeading: 'Introduction',
      showFirstVideo: true,
      showSecondVideo: false
    }
  },
  methods: {
    nextVideo () {
      this.$refs.vid1.hide()
      this.$refs.vid2.show()
    },
    showMap () {
      this.$refs.vid2.hide()
      this.$refs.gameMap.show()
    },
    goto (loc: string, heading: string, delay: number) {
      console.log('Maproom.goto')
      this.$store.dispatch('completeStage', 'Introduction')
      this.curtainHeading = heading
      this.curtainActive = true
      window.setTimeout(()=>{
        this.$router.push(loc)
      }, delay)
    },
  },
  mounted () {
    this.$store.dispatch('updateMyTeam', {lastLocation: 'Introduction'})
  }
});
